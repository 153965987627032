<template>
  <div class="container">
    <div class="client-profile-mobile" v-if="currentClient && object">
      <div class="container">
        <h2 class="semibold-font">{{ object.attributes.first_name }}</h2>
        <h2 class="semibold-font">{{ object.attributes.last_name }}</h2>
        <p class="regular-font">{{ $t('client') }}: {{ currentClient.login }}</p>
      </div>
      <div class="container client-profile-address">
        <div class="client-profile-address-title">{{ $t('address') }}</div>
        <div class="semibold-font client-profile-address-value mt-2">{{ object.attributes.street }} {{ object.attributes.house_number }} {{ object.attributes.apartment_number }}</div>
        <div class="semibold-font client-profile-address-value">{{ object.attributes.postcode }} {{ object.attributes.city }}</div>
        <div class="semibold-font client-profile-address-value">{{ $t(object.attributes.correspondence_country) }} </div>
        <div class="client-profile-address-title mt-6">{{ $t('correspondenceAddress') }}</div>
        <div class="semibold-font client-profile-address-value mt-2">{{ object.attributes.correspondence_street }} {{ object.attributes.correspondence_house_number }} {{ object.attributes.apartment_number }}</div>
        <div class="semibold-font client-profile-address-value">{{ object.attributes.correspondence_postcode }} {{ object.attributes.correspondence_city }}</div>
        <div class="semibold-font client-profile-address-value">{{ $t(object.attributes.correspondence_country) }} </div>
        <div class="client-profile-address-title mt-6">{{ $t('contact_data') }}</div>
        <div class="semibold-font client-profile-address-value mt-2">{{ object.attributes.phone }}</div>
        <div class="semibold-font client-profile-address-value mt-2">{{ object.attributes.email }}</div>
      </div>
      <div class="container mt-2 mb-2">
        <div class="see-more" @click="displayUpdateContactDetailsModal()">
          {{ $t('updateContactDetails') }}
          <v-icon
            small
            class="ml-2 arrow-icon"
          >
            fas fa-arrow-right
          </v-icon>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="container mt-4 mb-4">
        <div class="semibold-font mb-4">{{ $t('bank_accounts') }}</div>
        <div class="see-more" @click="displayBankAccountModal()">
          {{ $t('updateAccountNumber') }}
          <v-icon
            small
            class="ml-2 arrow-icon"
          >
            fas fa-arrow-right
          </v-icon>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="container mt-4 mb-12">
        <div class="see-more" @click="displayChangePasswordModal()">
          {{ $t('change_password') }}
          <v-icon
            small
            class="ml-2 arrow-icon"
          >
            fas fa-arrow-right
          </v-icon>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="container mt-4 mb-12">
        <div class="see-more" @click="displayTermsModal()">
          {{ $t('terms') }}
          <v-icon
            small
            class="ml-2 arrow-icon"
          >
            fas fa-arrow-right
          </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'account',
  data () {
    return {
      object: null
    }
  },

  components: {
  },
  computed: {
    ...mapGetters({ currentClient: 'currentClient' })
  },
  created () {
    this.$http.get(`client_accounts/${this.currentClient.id}/client_details`)
      .then(response => {
        this.object = response.data
      })
  },
  methods: {
    closeUserProfile () {
      this.$store.dispatch('closeShowUserProfile')
    },
    displayUpdateContactDetailsModal () {
      this.$store.dispatch('displayShowUpdateContactDetailsModal')
    },
    displayBankAccountModal () {
      this.$store.dispatch('displayShowUpdateAccountNumberMultipleDocumentsModal')
    },
    displayChangePasswordModal () {
      this.$store.dispatch('displayShowChangePassword')
    },
    displayTermsModal () {
      this.$store.dispatch('displayShowTerms')
    }
  }
}
</script>

<style lang="scss">
  .client-profile-mobile {
    .see-more {
      color: #d2116e !important;
      text-decoration: none;

      .arrow-icon {
        color: #d2116e !important;
      }
    }

    .see-more:hover {
      color: rgba(0, 0, 0, 0.54) !important;
      cursor: pointer;

      .arrow-icon {
        margin-right: 8px !important;
        margin-left: 0px !important;
        color: rgba(0, 0, 0, 0.54) !important;
      }
    }
  }

  .client-profile-address {
    height: 405px;
    background-color: #d2116e;
    border-radius: 20px;
    // background: transparent url('./../assets/main-background-pink.svg') 0% 0% no-repeat padding-box !important;
    padding-top: 20px;
    padding-left: 30px;
  }

  .client-profile-address-title {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
  }

  .client-profile-address-value {
    color: white;
  }
</style>
